import * as React from 'react'
import LocationDocuments from '../../../../components/Location/LocationDocuments'
import { Box } from '@mui/material'
import { navigate } from 'gatsby'
import AppPageWraper from '../../../../components/PageWrappers/AppPageWraper'

export default function (props) {
    return (
        <AppPageWraper disableNavigation disableGeoSnapShooter>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <LocationDocuments
                    locationId={props.params.locationId}
                    onDocumentsChanged={async (input) => {
                        navigate('..')
                    }}
                    onCancel={async () => {
                        navigate('..')
                    }}
                />
            </Box>
        </AppPageWraper>
    )
}
