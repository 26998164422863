import React from 'react'
import { Box, Icon, Menu, MenuItem, Typography } from '@mui/material'
import CameraAltRoundedIcon from '@mui/icons-material/CameraAltRounded'
import DynamicLoadingButton from '../input/DynamicLoadingButton'
import Webcam from 'react-webcam'
import CameraswitchRoundedIcon from '@mui/icons-material/CameraswitchRounded'
import CameraIcon from '@mui/icons-material/Camera'
import { uploadImageFile } from '../../utils/files'
import CancelIcon from '@mui/icons-material/Cancel'
import { uploadFile } from '../../api/files'
import createAlertSnackbar from '../modals/snackbars'
import CircularProgress from '@mui/material/CircularProgress'
import useInterval from '../../services/useInterval'

export default function ({
    locationId,
    onDocumentsChanged,
    onCancel = async () => {},
}: {
    locationId: string
    onDocumentsChanged: (input: any) => Promise<void>
    onCancel?: () => Promise<void>
}) {
    const webcamRef = React.useRef<any>(null)
    const [uploadProgress, setUploadProgress] = React.useState<number>(0)
    const [imageLoading, setImageLoading] = React.useState<boolean>(false)
    const [deviceId, setDeviceId] = React.useState({})
    const [devices, setDevices] = React.useState<any>([])
    const [device, setDevice] = React.useState<any>(null)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const [imageSource, setImageSource] = React.useState<any>(null)
    const { openAlert, AlertComponent } = createAlertSnackbar('warning', 2)
    const [mediaDataReady, setMediaDataReady] = React.useState(false)
    const [cameraWidth, setCameraWidth] = React.useState<number>(0)
    const cameraHeight = 550

    React.useEffect(() => {
        const device = devices.find((device: any) => device.deviceId === deviceId)
        setDevice(device)
    }, [devices, deviceId])

    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    async function clearCamera() {
        setImageSource(null)
    }

    const handleMenuItemClick = (clickedDeviceId: string) => {
        setDeviceId(clickedDeviceId)
        setAnchorEl(null)
    }

    const parentRef = React.useRef<any>(null)

    function updateCameraWidth() {
        if (parentRef.current) {
            const newWidth = (parentRef.current || { offsetWidth: 0 }).offsetWidth || 0
            setCameraWidth(newWidth)
        }
    }

    React.useEffect(updateCameraWidth, [parentRef])

    const handleDevices = React.useCallback(
        (mediaDevices: MediaDeviceInfo[]) => {
            const availableDevices = mediaDevices.filter(({ kind }: any) => kind === 'videoinput')
            if (availableDevices.length > devices.length) {
                setDevices(availableDevices)
                if (!deviceId) {
                    setDeviceId(availableDevices[0].deviceId)
                }
            }
        },
        [setDevices]
    )

    const captureImage = React.useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot()
        setImageSource(imageSrc)
    }, [webcamRef])

    function onUploadFileError(error: Error) {
        setImageLoading(false)
        openAlert(error.toString())
    }

    async function onConfirmImage(imageSource: string) {
        const onFormDataReady = async (formData: FormData) => {
            try {
                const fileResponse = await uploadFile(
                    `/documents/locations/${locationId}`,
                    formData,
                    setUploadProgress
                )
                await onDocumentsChanged(fileResponse)
                setUploadProgress(100)
            } catch (error) {
                return
            } finally {
                setImageLoading(false)
            }
        }
        const response = await fetch(imageSource)
        const blob = await response.blob()
        uploadImageFile(blob, onFormDataReady, onUploadFileError)
    }

    useInterval(
        checkDevices,
        // Passing in the delay parameter. null stops the counter.
        !(mediaDataReady && deviceId) ? 1000 : null
    )

    async function checkDevices() {
        const mediaDevices = await navigator.mediaDevices.enumerateDevices()
        handleDevices(mediaDevices)
    }

    React.useEffect(() => {
        checkDevices()
    }, [handleDevices])

    const videoConstraints: MediaTrackConstraints = {
        // width: cameraWidth,
        height: cameraHeight,
        // facingMode: 'user',
    }

    return (
        <Box
            ref={parentRef}
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {AlertComponent}
            {imageSource ? (
                <Box key={1}>
                    <img
                        style={{
                            height: `${cameraHeight}px`,
                            objectFit: 'contain',
                            zIndex: 999201,
                        }}
                        src={imageSource}
                    />
                </Box>
            ) : (
                <Webcam
                    height={cameraHeight}
                    // width={cameraWidth}
                    audio={false}
                    mirrored={false}
                    ref={webcamRef}
                    onUserMedia={() => {
                        setMediaDataReady(true)
                    }}
                    videoConstraints={{ ...videoConstraints, deviceId: device?.deviceId }}
                    screenshotQuality={1}
                    screenshotFormat="image/webp"
                />
            )}
            {!(mediaDataReady && deviceId) && (
                <Box
                    key={11}
                    sx={{
                        position: 'absolute',
                        left: `0px`,
                        top: `0px`,
                        height: `${cameraHeight}px`,
                        width: `${cameraWidth}px`,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <Typography fontSize="13px">
                        Virker kameraet ikke?
                        <br />
                        Prøv at genindlæs siden
                    </Typography>
                    <CircularProgress />
                </Box>
            )}
            <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox',
                }}
            >
                {devices.map((deviceFromList: any, index: number) => (
                    <MenuItem
                        key={index}
                        onClick={() => handleMenuItemClick(deviceFromList.deviceId)}
                        selected={deviceFromList.deviceId === deviceId}
                    >
                        {deviceFromList.label}
                    </MenuItem>
                ))}
            </Menu>
            {imageSource ? (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'stretch',
                            width: '100%',
                        }}
                    >
                        <DynamicLoadingButton
                            sx={{ margin: null }}
                            variant="contained"
                            color="primary"
                            onClick={async () => onConfirmImage(imageSource)}
                            startIcon={<CameraAltRoundedIcon />}
                        >
                            Ja - anvend billede
                        </DynamicLoadingButton>
                        <DynamicLoadingButton
                            variant="outlined"
                            color="primary"
                            sx={{ margin: null }}
                            onClick={clearCamera}
                            startIcon={<CancelIcon />}
                        >
                            Nej - tag nyt billede
                        </DynamicLoadingButton>
                    </Box>
                </>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                        width: '100%',
                    }}
                >
                    <DynamicLoadingButton
                        id="lock-button"
                        aria-haspopup="listbox"
                        aria-controls="lock-menu"
                        aria-label="when device is locked"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClickListItem}
                        sx={{ margin: null }}
                        variant="outlined"
                        startIcon={<CameraswitchRoundedIcon />}
                    >
                        Skift kamera
                    </DynamicLoadingButton>

                    <DynamicLoadingButton
                        sx={{ margin: null }}
                        onClick={captureImage}
                        endIcon={<CameraIcon sx={{ fontSize: '24px' }} />}
                    >
                        Tag billede
                    </DynamicLoadingButton>
                </Box>
            )}
            <DynamicLoadingButton
                sx={{ margin: null }}
                variant="outlined"
                color="error"
                onClick={onCancel}
                // startIcon={<CameraAltRoundedIcon />}
            >
                Annullér
            </DynamicLoadingButton>
        </Box>
    )
}
